import React, { useState, useEffect } from "react";
import { colorGradient, isNumber } from '../modules/util.js';
import Gradient from '../components/gradient.js';
import SearchField from '../components/search.js';
import { MapContainer, TileLayer, Marker, Popup, Circle, CircleMarker, useMapEvents } from 'react-leaflet'
import queryString from 'query-string'

const Map = () => {
    function EventListener() {
        const [position, setPosition] = useState({ lat: 49.460983, lng: 11.061859 })
        const [connections, setConnections] = useState([])

        const map = useMapEvents({
            click(e) {
                console.log("Click event")
                window.history.pushState("", "", `?lat=${e.latlng.lat}&lng=${e.latlng.lng}`);
                setPosition(e.latlng)
            }
        })

        useEffect(() => {
            const qs = window?.location?.search ? queryString.parse(window?.location?.search) : {}
            if (isNumber(qs.lat) && isNumber(qs.lng)) {
                console.log("Browser location update")
                setPosition(qs);
            }

            map.on('geosearch/showlocation', (loc) => {
                console.log("Show location event")
                const latLng = { lat: loc.location.y, lng: loc.location.x }
                window.history.pushState(latLng, "", `?lat=${latLng.lat}&lng=${latLng.lng}`);
                setPosition(latLng)
            })

        }, [])

        useEffect(() => {
            console.log("Location update Stations")
            console.log(position)
            map.flyTo(position, map.getZoom())
            setConnections([])

            fetch(`/api/?lat=${position.lat}&lng=${position.lng}`, {
                method: "GET"
            })
                .then(response => response.json())
                .then(resultData => {
                    var conns = {};                    ;
                    resultData.connections.forEach((s, _) => {
                        conns[s.start.id] = {
                            pos: [s.start.coordinates.lat, s.start.coordinates.lng],
                            name: `${s.start.name}`,
                            time: 0,
                        }
                        s.connections.forEach((c, _) => {
                            let curr = conns[c.station.id]
                            if (!curr || curr.time > c.meta.time_s) {
                                conns[c.station.id] = {
                                    pos: [c.station.coordinates.lat, c.station.coordinates.lng],
                                    name: `${c.station.name}`,
                                    time: c.meta.time_s,
                                }
                            }
                        })
                    })
                    var cons = []
                    for (const id in conns) {
                        const station = conns[id]
                        cons.push(
                            <CircleMarker key={`station_${id}`}
                                center={station.pos}
                                radius={8}
                                color={colorGradient(Math.min(station.time / 60.0  / 60.0, 1.0))}
                                onMouseOver={(e) => {
                                    e.target.openPopup();
                                }}
                                onFocus={(e) => {
                                    //TODO
                                }}
                                onMouseOut={(e) => {
                                    e.target.closePopup();
                                }}
                                onBlur={(e) => {
                                    //TODO
                                }}
                            >
                                <Popup>
                                    <h3>{station.name}</h3>
                                    <p>{Math.round(station.time / 60.0)} min</p>
                                </Popup>
                            </CircleMarker>) 
                    }
                    setConnections(cons)
                })
        }, [position]);

        /*
         TDOO       
         use map.locate()
        locationfound(e) {
                 setPosition(e.latlng)
                 map.flyTo(e.latlng, map.getZoom())
               }, */


        return position === null ? null : (
            <>
                {connections}
                <Marker position={position} key={`marker`}>
                    <Popup>You are here</Popup>
                </Marker>
                <Circle
                    key={`marker_radius`}
                    center={position}
                    radius={600} // Hard coded to 600 meter right now
                    weight={2}	
                >
                </Circle>
            </>
        )
    }

    if (typeof window === 'undefined') {
        console.log("No window")
        return null
    }
    console.log("Load map")
    return (
        <MapContainer
            zoom={13}
            scrollWheelZoom={true}
            defaultBaseMap="OpenStreetMap"
            center={{ lat: 49.460983, lng: 11.061859 }} // TODO 
            id="map"
            maxBounds={[[46.0, 5.0], [55.3, 15.2]]}
            maxZoom={15}
            minZoom={6}
        >
            <TileLayer
                attribution='<a href="https://osm.org/copyright">OpenStreetMap</a> | <a href="https://gtfs.de/">gtfs.de</a>'
                url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
            />
            <Gradient />
            <SearchField />
            <EventListener />
        </MapContainer >)
}
export default Map