import React, { useState } from "react"
import { AiFillInfoCircle } from "react-icons/ai";
import Seo from '../components/seo'
import { Container } from "react-bootstrap";
import Info from '../components/info'
import Map from '../components/map.js';

const Main = (props) => {

  const [show, setShow] = useState(false);

  return (
    <>
      <Seo title="Karte" />
      <Info show={show} onHide={() => setShow(false)} />
      <Container fluid className="px-0 main">
        <AiFillInfoCircle id="info-btn" class="leaflet-control-zoom leaflet-bar leaflet-control" onClick={() => setShow(true)} />
        <Map>
        </Map>
      </Container >
    </>
  )
}

export default Main;
