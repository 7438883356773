import { useEffect } from "react"
import { OpenStreetMapProvider } from 'leaflet-geosearch'

import { GeoSearchControl } from 'leaflet-geosearch';
import {useMap} from 'react-leaflet';

const SearchField = ({apiKey}) => {
  const map = useMap();

  // TODO use directly and with reverse:
  // https://nominatim.org/release-docs/develop/api/Reverse/
  const provider = new OpenStreetMapProvider({
    params: {
      // TODO USE REAL MAIL FOR MORE REQUESTS
      //email: '', 
      'accept-language': 'de',
      'countrycodes': 'de',
      limit: 10,
    }
  }); 

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider, // required
    style: 'bar', // optional: bar|button  - default button
    showMarker: false, // optional: true|false  - default true
    searchLabel: 'Addresse', // optional: string      - default 'Enter address'
    updateMap: false,
    autoClose: false,
    retainZoomLevel: true,
    autoComplete: true, // optional: true|false  - default true
    autoCompleteDelay: 250, // optional: number      - default 250
  });

  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, [])

  return null;
}

export default SearchField;
