import React from 'react';
import { colorGradient } from '../modules/util.js';

const Gradient = () => {
    var gradient = [];
    var len = 25;
    for (var i = 0; i <= len; i++) {
        var value = i / len;
        gradient.push(<div key={`gradient_` + value} style={{ backgroundColor: colorGradient(value) }} textcontent={"value=" + value}></div>);
    }
    return (
        <div id="gradient">
            <h3 style={{ float: "left" }}>&lt;5min</h3>
            {gradient}
            <h3 style={{ float: "left" }}>&gt;1h</h3>
        </div>)
}
export default Gradient