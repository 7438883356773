
function colorGradient(percentage) {
  //value from 0 to 1
  var hue = ((1 - percentage) * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
}

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

export { colorGradient, isNumber };
